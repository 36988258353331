import $ from "jquery";
import activeLazyLoad from "../lazy-load";
import { laptopBreakPoint } from "../application";
import "./swiper";
import "./dropdown_nav";
import "./what-we-do";
import "./mobile_menu";
import "../shared/contact";
import "./miners";
import CookieBar from "../shared/cookie";
import CookiesHandler from "../shared/cookiesHandler";
import HireBanner from "./hire_banner";

$(document).ready(() => {
  const cookiesHandler = new CookiesHandler();
  new CookieBar(cookiesHandler).init();

  if (import.meta.env.VITE_DISPLAY_HIRING_BANNER === "true") {
    new HireBanner(cookiesHandler).init();
  }

  const lazyLoadElements = $(".developer__image");
  activeLazyLoad(lazyLoadElements);
});
